import { Injectable, Inject } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SETTINGS_SERVICE, SettingsService } from 'ziti-console-lib';

import { isEmpty } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class ZitiApiInterceptor implements HttpInterceptor {
    constructor(@Inject(SETTINGS_SERVICE) private settingsService: SettingsService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (isEmpty(this.settingsService?.settings?.session)) {
            return next.handle(req);
        } else {
            let request = this.addAuthPolicyId(req);
            request = this.addAuthToken(request);
            return next.handle(request);
        }
    }

    private addAuthToken(request: HttpRequest<any>) {
        const session = this.settingsService.settings.session;
        return request.clone({ setHeaders: { 'zt-session': session.id } });
    }

    private addAuthPolicyId(request: HttpRequest<any>) {
      const url = request.url;
      const authPolicyId = this.settingsService.settings.nfAdminAuthPolicyId;
      if (request.method === 'GET' && url.indexOf('edge/management/v1/identities') >= 0 && !isEmpty(authPolicyId)) {
        let filter: any = request.params.get('filter')?.toString();
        if (isEmpty(filter)) {
          const urlParams = new URLSearchParams(new URL(url).search);
          filter = urlParams.get('filter')?.toString();
          if (isEmpty(filter)) {
            filter = `authPolicyId != "${authPolicyId}"`;
            request = request.clone({
              params: request.params.set('filter', filter)
            });
          } else {
            filter += ` and authPolicyId != "${authPolicyId}"`;
            filter = filter.replace('name contains "%" and ', '');
            filter = filter.replace('name contains "%"', '');
            request = request.clone({
              url: this.setURLParam(url, 'filter', filter)
            });
          }
        } else {
          filter += `authPolicyId != "${authPolicyId}"`;
          request = request.clone({
            params: request.params.set('filter', filter)
          });
        }
      }
      return request;
    }

    setURLParam(url: any, param: any, value: any) {
      const urlObj = new URL(url);
      const searchParams = urlObj.searchParams;
      searchParams.set(param, value);
      return urlObj.toString();
    }
}
